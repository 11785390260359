const $ = require("../../node_modules/jquery/dist/jquery.min")

module.exports = {
  init: () => {
    $(".index-row").each((i, element) => {
      const indexRow = $(element)

      indexRow.find("a").click(function() {
        const jq = $(this)

        const filter = jq.data("filter")

        if (filter) {
          $(".article").hide()
          $(`.article-${filter}`).show()
        } else {
          $(".article").show()
        }

        indexRow.find(".index-row--active").removeClass("index-row--active")
        jq.parent().addClass("index-row--active")

        return false
      })
    })
  },
}
