require("../../node_modules/select2/dist/js/select2.full.min.js");

module.exports = {
  init: () => {
    $(".select").each((i, element) => {
      const select = $(element);
      $(select).select2({
        minimumResultsForSearch: Infinity
      });
    });
  }
};
