const $ = require("../../node_modules/jquery/dist/jquery.min");

module.exports = {
  init: () => {
    $(".expandbox").each((i, element) => {
      const accordion = $(element);
      accordion.find(".expandbox-link").click(function() {
        const accordionItem = $(this);
        accordionItem.closest(".expandbox").toggleClass("expandbox--expanded");
        return false;
      });
    });
  }
};
