require("../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js");

module.exports = {
  init: () => {
    $(".popup-trigger").each(function() {
      $(this).magnificPopup({
        type: "inline",
        preloader: false,
        focus: "#name",
        mainClass: "mfp-fade",
        removalDelay: 300,

        // When elemened is focused, some mobile browsers in some cases zoom in
        // It looks not nice, so we disable it:
        callbacks: {
          beforeOpen: function() {
            if ($(window).width() < 700) {
              this.st.focus = false;
            } else {
              this.st.focus = "#name";
            }
          }
        }
      });
    });
  }
};
