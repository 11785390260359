require("../../node_modules/slick-carousel/slick/slick.js");

module.exports = {
  init: () => {
    $(".teaserbox-slideshow").each((i, slider) => {
      const $slider = $(slider);
      const slideCount = $slider.find(".teaserbox").length;
      if (slideCount < 2) {
        $slider.find(".teaserbox-controls").hide();
      }
      $slider.slick({
        prevArrow: $slider.find(".teaserbox-prev"),
        nextArrow: $slider.find(".teaserbox-next"),
        rows: 0,
        dots: true,
        slide: ".teaserbox",
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: $slider.find(".teaserbox-controls-dots")
      });
    });
  }
};
