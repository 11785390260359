const $ = require("../../node_modules/jquery/dist/jquery.min")

module.exports = {
  init: () => {
    $(".tab-links").each((i, element) => {
      const tabLinks = $(element)

      tabLinks.find("a").click(function() {
        const jq = $(this)

        const tab = jq.data("tab")

        $(".tab-content").hide()
        $(tab).show()

        tabLinks.find(".tab-links--active").removeClass("tab-links--active")
        jq.parent().addClass("tab-links--active")

        return false
      })
    })
  },
}
