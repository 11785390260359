require("../../node_modules/slick-carousel/slick/slick.js");

module.exports = {
  init: () => {
    $(".slideshow").each((i, slider) => {
      const $slider = $(slider);
      $slider.slick({
        prevArrow: $slider.find(".slideshow-prev"),
        nextArrow: $slider.find(".slideshow-next"),
        rows: 0,
        dots: false,
        slide: ".slideshow-item",
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1020,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
    });
  }
};
