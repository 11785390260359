const $ = require("../../node_modules/jquery/dist/jquery.min")

module.exports = {
  init: () => {
    $(".accordion").each((i, element) => {
      const accordion = $(element)
      accordion.find(".accordion-item-title").click(function() {
        const accordionItem = $(this)
        accordionItem.parent().toggleClass("accordion-item--active")
      })
    })
  },
}
