require("./_config");

global.jQuery = require("jquery");
global.$ = jQuery;

const $ = require("../../node_modules/jquery/dist/jquery.min");

const ElementQueries = require("../../node_modules/css-element-queries/src/ElementQueries");
const mobileMenu = require("./_mobilemenu");
const matchHeight = require("./_matchheight");
const slideshow = require("./_slideshow");
const select = require("./_select");
const teaserbox = require("./_teaserbox");
const indexRow = require("./_indexRow");
const accordion = require("./_accordion");
const expandbox = require("./_expandbox");
const tabLinks = require("./_tabLinks");
const popup = require("./_popup");
const validate = require("./_validate");
const aside = require("./_aside");

$(() => {
  matchHeight.init();
  mobileMenu.init();
  slideshow.init();
  select.init();
  teaserbox.init();
  indexRow.init();
  accordion.init();
  expandbox.init();
  tabLinks.init();
  popup.init();
  validate.init();
  aside.init();
});

ElementQueries.listen();
