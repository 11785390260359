require("../../node_modules/jquery-validation/dist/jquery.validate.js");

module.exports = {
  init: () => {
    $.extend($.validator.messages, {
      required: "Dette felt er påkrævet",
      email: "Indtast venligst en gyldig email"
    });

    $.validator.addMethod("validateEmail", function(value, element) {
      const emailRegex = /^\b[A-Z0-9._%-ÆØÅ]+@[A-Z0-9.\-ÆØÅ]+\.[A-Z]{2,4}\b$/i
      return emailRegex.test(value)
    }, "Indtast venligst en gyldig email");

    $("#contact-form").validate({
      ignore: []
    });
    $("#newsletter-form").validate();
    $("#member-form").validate({
      ignore: []
    });
    $("#bill-form").validate({
      ignore: []
    });
  }
};
