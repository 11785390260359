const $ = require("../../node_modules/jquery/dist/jquery.min");

module.exports = {
  init: () => {
    const burger = $(".header-burger");
    const mobileMenu = $(".mobilemenu");
    const expandableList = $(".mobilemenu-list-item--expandable > .mobilemenu-list-item-arrow");

    const openMobileMenu = () => {
      const isActive = mobileMenu.hasClass("mobilemenu--active");

      if (isActive) {
        burger.removeClass("header-burger--active");
        mobileMenu.removeClass("mobilemenu--active");
      } else {
        burger.addClass("header-burger--active");
        mobileMenu.addClass("mobilemenu--active");
      }
      return false;
    };

    const expandList = element => {
      $(element)
        .closest(".mobilemenu-list-item--expandable")
        .toggleClass("mobilemenu-list-item--expanded");
    };

    burger.click(openMobileMenu);
    burger.addClass("header-burger-loaded");

    expandableList.click(function() {
      expandList($(this));
      return false;
    });
    $(window)
      .on("resize", function() {
        let headerHeight = $(".header").outerHeight();
        $(".mobilemenu").css("top", headerHeight);
      })
      .resize();
  }
};
